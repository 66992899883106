import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollIntoView from 'react-scroll-into-view-if-needed';
import {
  faHome,
  faUser,
  faProjectDiagram,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="flex items-center justify-between p-2 bg-blue-300">
      <button
        name="menuButton"
        onClick={() => setIsOpen(!isOpen)}
        className="md:hidden"
        aria-label="Toggle navigation menu"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-6 w-6 text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
      <div
        className={`${
          isOpen ? 'flex' : 'hidden'
        } flex-row justify-around w-full md:flex nav-menu`}
      >
        <ScrollIntoView selector="#home">
          <a
            href="#home"
            className="flex flex-col items-center py-2 px-4 text-blue-700 hover:text-blue-500 active:text-blue-900"
          >
            <FontAwesomeIcon
              icon={faHome}
              className="text-2xl mb-1 text-red-500 hover:text-red-300 active:text-red-700"
            />
            Home
          </a>
        </ScrollIntoView>
        <ScrollIntoView selector="#about">
          <a
            href="#about"
            className="flex flex-col items-center py-2 px-3 text-blue-700 hover:text-blue-500 active:text-blue-900"
          >
            <FontAwesomeIcon
              icon={faUser}
              className="text-2xl mb-1 text-green-500 hover:text-green-300 active:text-green-700"
            />
            About Me
          </a>
        </ScrollIntoView>
        <ScrollIntoView selector="#projects">
          <a
            href="#projects"
            className="flex flex-col items-center py-2 px-3 text-blue-700 hover:text-blue-500 active:text-blue-900"
          >
            <FontAwesomeIcon
              icon={faProjectDiagram}
              className="text-2xl mb-1 text-yellow-500 hover:text-yellow-300 active:text-yellow-700"
            />
            Projects
          </a>
        </ScrollIntoView>
        <ScrollIntoView selector="#contact">
          <a
            href="#contact"
            className="flex flex-col items-center py-2 px-4 text-blue-700 hover:text-blue-500 active:text-blue-900"
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              className="text-2xl mb-1 text-purple-500 hover:text-purple-300 active:text-purple-700"
            />
            Lets build something
          </a>
        </ScrollIntoView>
      </div>
    </nav>
  );
}

export default Navigation;
