import React from 'react';
import { FaGithub, FaReact, FaLinkedin, FaNodeJs } from 'react-icons/fa';
import {
  SiFirebase,
  SiTailwindcss,
  SiSass,
  SiBootstrap,
  SiJavascript,
  SiHtml5,
  SiCss3,
} from 'react-icons/si';
import ScrollIntoView from 'react-scroll-into-view-if-needed';

function Footer() {
  return (
    <footer className="bg-blue-500 text-white p-10">
      <div className="tech-icons flex justify-around text-4xl mb-5">
        <FaReact />
        <FaGithub />
        <SiFirebase />
        <SiBootstrap />
        <SiTailwindcss />
        <SiSass />
        <FaNodeJs />
        <SiJavascript />
        <SiHtml5 />
        <SiCss3 />
      </div>
      <div className="container mx-auto px-6 md:flex justify-between items-center">
        <div className="text-center md:text-left mb-6 md:mb-0">
          <h3 className="text-lg mb-2">Charlie's App Factory</h3>
          <p className="text-sm">
            Remetinečka cesta 102D, <br /> 10020 Zagreb <br /> Croatia
          </p>

          <p className="text-sm">
            Email:{' '}
            <a href="mailto:info@charliesappfactory.hr">
              info@charliesappfactory.hr
              <span className="ml-1">(click to send email)</span>
            </a>
          </p>
          <p className="text-sm">Mob. phone: (+385) 95 871-6536</p>
        </div>

        <div className="flex justify-center md:justify-end space-x-10">
          <ScrollIntoView selector="#about" className="text-sm">
            <a href="#about">About</a>
          </ScrollIntoView>
          <ScrollIntoView selector="#home" className="text-sm">
            <a href="#home">Services</a>
          </ScrollIntoView>
          <ScrollIntoView selector="#contact" className="text-sm">
            <a href="#contact">Contact</a>
          </ScrollIntoView>
        </div>

        <div className="flex justify-center md:justify-end space-x-4 mt-6 md:mt-0">
          <a
            href="https://github.com/corti889"
            aria-label="Github"
            rel="noreferrer"
            target="_blank"
          >
            <FaGithub size={24} />
          </a>
          <a
            href="https://www.linkedin.com/in/nikola-cartolovni-b8635a254"
            aria-label="LinkedIn"
            rel="noreferrer"
            target="_blank"
          >
            <FaLinkedin size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
